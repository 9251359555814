"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useCart } from "@/context/CartContext";
import {
  addProductToCart,
  clearCart,
  createCart,
  createCheckout,
  fetchCart,
  fetchProducts,
  removeProductFromCart,
  updateCartLineQuantity,
} from "@/utils/Shopify/products";
import ShippingProgressBar from "../story/progressbar";
import {
  recommendedSetProductId,
  recommendedSetVariantId,
} from "@/utils/baseEnvConstant";
import { PRODUCT_LIST, USA_REGION_ID } from "@/utils/constants";
import axios from "@/utils/axios";

// interface Cart {
//     lines: {
//         edges: { node: CartItem }[];
//     };
// }

// interface CartItem {
//     id: string;
//     variantId: string;
//     title: string;
//     price: number;
//     quantity: number;
// }

const hsaFsaFaq = [
  {
    title: "How do I pay with my HSA/FSA card?",
    description:
      '<p>The Aletha Health products are HSA/FSA eligible with TrueMed. Follow these steps after proceeding to checkout:</p><ol><li>Checkout as a guest. <strong>DO NOT</strong> enter in your express checkout Shop Pay code if prompted.</li><li>Select "<strong>TrueMed - Pay with HSA/FSA</strong>" as your payment method.</li><li>Click "<strong>Complete order</strong>" to be taken to the TrueMed site to enter your payment details, complete the FSA/HSA eligibility survey, and finalize your order.</li></ol><p>If approved, TrueMed will send you the necessary paperwork and information for reimbursement.</p>',
  },
  {
    title: "What if I don't follow the HSA/FSA payment instructions?",
    description:
      '<p>If you do not follow the HSA/FSA checkout instructions described in the above FAQ, your purchase <strong>WILL NOT</strong> be eligible for reimbursement.</p><p>You <strong>MUST</strong> checkout using the "TrueMed - Pay with HSA/FSA" payment option because this is where you\'ll complete the eligibility survey and complete payment.</p><p>TrueMed will issue you the appropriate documentation to submit to your HSA/FSA provider for reimbursement. Aletha Health <strong>CANNOT</strong> provide this documentation.</p>',
  },
  {
    title: "International VAT/GST",
    description:
      "<p>All international customers will be responsible for VAT/GST upon collection of the product from its final destination. All of our products ship from the US.</p>",
  },
];

export default function ProductCartSection() {
  const [error, setError] = useState<string | null>(null);
  const { itemCount, setItemCount } = useCart();

  const { cart, setCart } = useCart() as {
    cart: any | null;
    setCart: (cart: any | null) => void;
  };
  const [showRecommended, setShowRecommended] = useState(true);
  const [shopifyPrice, SetShopifyPrice] = useState<any>("");
  const [compareAtPrice, SetCompareAtPrice] = useState<any>("");
  const [productList, setProductList] = useState<any[]>([]);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const fetchProductsList = async () => {
    try {
      const response: any = await axios.get(
        `${PRODUCT_LIST}?region_id=${USA_REGION_ID}`
      );
      setProductList(response?.data?.data);
    } catch (error) {
      console.log("Error in fetching connect us Data ");
    }
  };

  useEffect(() => {
    fetchProductsList();
  }, []);

  const handleCloseRecommended = () => {
    setShowRecommended(false);
  };

  useEffect(() => {
    // Whenever the flyover cart is opened, fetch the cart and update state
    const fetchCartData = async () => {
      let storedData = localStorage.getItem("cartCheckoutData");
      let cartId = storedData ? JSON.parse(storedData).cartId : null;
      if (cartId) {
        const updatedCart = await fetchCart(cartId);
        setCart(updatedCart);
        setItemCount(
          updatedCart.lines.edges.reduce(
            (total: any, edge: any) => total + edge.node.quantity,
            0
          )
        );
      }
    };
    fetchCartData();
  }, [setCart, setItemCount]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const data = await fetchProducts();
        data.forEach((shopifynode: any) => {
          if (shopifynode.node.id.includes(recommendedSetProductId)) {
            shopifynode.node.variants.edges.forEach((edge: any) => {
              if (edge.node.id.includes(recommendedSetVariantId[0])) {
                SetShopifyPrice("$" + Number(edge.node.priceV2.amount));
                SetCompareAtPrice(
                  "$" + Number(edge.node.compareAtPriceV2.amount)
                );
              }
            });
          }
        });
      } catch (err) {
        setError("Failed to fetch products");
      }
    };
    loadProducts();
  }, []);

  useEffect(() => {
    if (!cart?.lines?.edges?.length) {
      return;
    }
    const cartItems = cart?.lines?.edges || [];
    const products = cartItems.map((item: any) => {
      const productId = (item.node.merchandise.product.id)?.split("/").pop();
      const sku = item.node.merchandise.sku;
      const variantId = (item.node.merchandise.id)?.split("/").pop();
      const productName = item.node.merchandise.product.title;
      const price = item.node.merchandise.priceV2.amount;
      const quantity = item.node.quantity;
      return {
        item_id: productId,
        sku: sku,
        variant: variantId,
        item_name: productName || "Untitled",
        price: price || 0,
        quantity: quantity,
      };
    });

    window.dataLayer?.push({
      event: "addToCart",
      products: products,
    });
  }, [cart]);
  const handleUpdateProduct = async (lineId: string, newQuantity: number) => {
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;
    if (cartId) {
      try {
        const updatedCart = await updateCartLineQuantity(
          cartId,
          lineId,
          newQuantity
        );
        setCart(updatedCart);
        const newItemCount = updatedCart.lines.edges.reduce(
          (total: any, edge: any) => total + edge.node.quantity,
          0
        );
        setItemCount(newItemCount);
      } catch (err) {
        setError("Failed to update cart item quantity.");
      }
    }
  };

  const handleRemoveFromCart = async (line: any) => {
    let lineItemId = line?.node.id;
    if (typeof window.fbq === "function") {
      window.fbq("track", "removeFromCart", {
        content_ids: [lineItemId],
      });
    }
    window.dataLayer?.push({
      event: "Remove from Cart",
      ecommerce: {
        remove: [
          {
            item_id: lineItemId,
            variant: line.node.merchandise.id,
            price: line.node.merchandise.priceV2.amount,
            quantity: line.node.quantity,
          },
        ],
      },
    });
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;
    if (!cartId) return;
    try {
      const updatedCart = await removeProductFromCart(cartId, lineItemId);
      setCart(updatedCart);
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    } catch (error) {
      setError("Error removing product from cart.");
    }
  };

  const handleCheckout = async (checkoutType: any) => {
    let checkoutid;
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;
    if (cartId) {
      try {
        const checkout = await createCheckout(cartId);
        if (checkout && checkout.id) {
          checkoutid = checkout.id;
          localStorage.setItem(
            "cartCheckoutData",
            JSON.stringify({ cartId, checkoutid })
          );
          if (checkoutType === "normal") {
            window.location.href = checkout.webUrl;
          }
          if (checkoutType === "hsa") {
            let baseUrl = checkout.webUrl;
            let url = new URL(baseUrl);

            let params = new URLSearchParams(url.search);

            // Append the desired parameters
            params.append("origin", "shop_pay_c1");
            params.append("shop_pay_checkout_as_guest", "true");
            params.append("skip_shop_pay", "true");

            // Update the search part of the URL with the new query parameters
            url.search = params.toString();
            window.location.href = url.toString();
          }
        }
      } catch (error) {
        setError("Checkout error");
      }
    }
  };

  const handleGovx = () => {
    window.location.href =
      "https://auth.govx.com/shopify/verify?shop=christine-koth.myshopify.com&utm_source=shopify&utm_medium=govxid&utm_campaign=custom_link"; // Navigates to the external link
  };

  const handleAddToCartRecommended = async (
    productId: string,
    variantId?: string
  ) => {
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;

    if (cartId) {
      // Fetch the existing cart data
      const cartData = await fetchCart(cartId);

      // Clear the existing cart by removing all items
      const clearedCart = await clearCart(cartId);

      let updatedCart;
      if (variantId) {
        // If there's a variant, add the product with variant
        updatedCart = await addProductToCart(clearedCart.id, variantId, 1);
      }

      setCart(updatedCart);

      // Recalculate and update the total item count based on the updated cart
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);

      // Update local storage with current cartId and checkoutId (if any)
      const { checkoutId } = storedData
        ? JSON.parse(storedData)
        : { checkoutId: null };
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: updatedCart.id, checkoutId })
      );
    } else {
      // If no cart ID exists, create a new cart
      const newCartId = await createCart();
      const newCheckoutId = null;
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: newCartId, checkoutId: newCheckoutId })
      );

      // Fetch the newly created cart
      const cartData = await fetchCart(newCartId);
      setCart(cartData);

      // Add the product to the newly created cart
      let updatedCart;
      if (variantId) {
        updatedCart = await addProductToCart(newCartId, variantId, 1);
      } else {
        updatedCart = await addProductToCart(newCartId, productId, 1);
      }
      setCart(updatedCart);

      // Recalculate and update the item count
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    }
    setShowRecommended(false);
  };

  const calculateSubtotal = (cart: any) => {
    if (
      !cart ||
      !cart.lines ||
      !cart.lines.edges ||
      cart.lines.edges.length === 0
    ) {
      return "0.00"; // Return '0.00' if the cart is empty or structure is invalid
    }

    return cart.lines.edges
      .reduce((total: any, edge: any) => {
        const merchandise = edge.node.merchandise;

        // Check if merchandise and priceV2 exist
        if (merchandise && merchandise.priceV2 && merchandise.priceV2.amount) {
          const price = parseFloat(merchandise.priceV2.amount);
          const quantity = edge.node.quantity;
          return total + price * quantity;
        } else {
          console.warn(
            "Missing priceV2 or amount in merchandise:",
            merchandise
          );
          return total; // If price or quantity is missing, skip this item
        }
      }, 0)
      .toFixed(2); // Ensure subtotal is always a string with 2 decimal places
  };

  const subtotal = calculateSubtotal(cart);
  const freeShippingThreshold = 75;
  return (
    <>
      <div>
        <div className="cart-section d-flex mx-auto">
          <div className="w-100 bg-white">
            <div>
              <ShippingProgressBar
                subtotal={subtotal}
                freeShippingThreshold={freeShippingThreshold}
              />
              <div className="mx-4 mx-sm-5">
                {cart && cart.lines && cart.lines.edges.length > 0 ? (
                  cart?.lines.edges.map((line: any) => {
                    let imageSrc = "";
                    let subTitle = "";

                    const cartItem = productList.find((product: any) =>
                      line.node.merchandise.id.includes(
                        product.shopify_variant_id
                      )
                    );

                    if (cartItem) {
                      subTitle = cartItem.variant_sub_title;

                      if (
                        subTitle === "White" ||
                        line.node.merchandise?.selectedOptions?.[0]?.value ===
                          "White"
                      ) {
                        subTitle = "White";
                        if (line.node.merchandise?.product.title === "Mark") {
                          imageSrc = "/assets/images/v2/menu/mark.png";
                        } else if (
                          line.node.merchandise?.product.title === "Range"
                        ) {
                          imageSrc = "/assets/images/v2/menu/range.png";
                        } else if (
                          line.node.merchandise?.product.title === "The Set"
                        ) {
                          imageSrc = "/assets/images/v2/menu/the-set.png";
                        }
                      } else if (
                        subTitle === "Limited Edition Black" ||
                        line.node.merchandise?.selectedOptions?.[0]?.value ===
                          "Black"
                      ) {
                        subTitle = "Limited Edition Black";
                        if (line.node.merchandise?.product.title === "Mark") {
                          imageSrc =
                            "/assets/images/v2/menu/blackMarkCartImg.png";
                        } else if (
                          line.node.merchandise?.product.title === "Range"
                        ) {
                          imageSrc =
                            "/assets/images/v2/menu/blackRangeCart.png";
                        } else if (
                          line.node.merchandise?.product.title === "The Set"
                        ) {
                          imageSrc = "/assets/images/v2/menu/blackSetTrans.png";
                        }
                      } else if (
                        line.node.merchandise?.product.title === "Band"
                      ) {
                        imageSrc = "/assets/images/v2/menu/band.png";
                      } else if (
                        line.node.merchandise?.product.title === "Orbit"
                      ) {
                        imageSrc = "/assets/images/v2/menu/orbit.png";
                      } else if (
                        line.node.merchandise?.product.title ===
                        "Tight Hip, Twisted Core: The Key to Unresolved Pain"
                      ) {
                        imageSrc = "/assets/images/v2/menu/book.png";
                      }

                      if (
                        line.node.merchandise?.selectedOptions?.[0]?.value ===
                        "Paperback"
                      ) {
                        subTitle = "Paperback";
                      } else if (
                        line.node.merchandise?.selectedOptions?.[0]?.value ===
                        "eBook"
                      ) {
                        subTitle = "eBook";
                      }
                    }

                    return (
                      <div key={line.node.id} className="product-type-holder">
                        <div className="row ">
                          <div className="col-4">
                            <Image
                              src={imageSrc}
                              alt={line.node.merchandise?.product.title}
                              className="card-img-top w-100 h-100"
                              width={180}
                              height={180}
                              style={{
                                border:
                                  subTitle === "Limited Edition Black"
                                    ? "1px solid #848484"
                                    : "none",
                                borderRadius:
                                  subTitle === "Limited Edition Black"
                                    ? "14px"
                                    : "0px",
                              }}
                            />
                          </div>
                          <div className="col-8">
                            <div className="d-flex flex-row justify-content-between">
                              <p className="product-name text-dark">
                                {line.node.merchandise?.product.title}
                              </p>
                              <div>
                                <button
                                  onClick={() => handleRemoveFromCart(line)}
                                  style={{
                                    display: "block",
                                    border: "none",
                                    background: "none",
                                  }}
                                >
                                  <Image
                                    src="/assets/images/new-image/Close.png"
                                    alt="Remove"
                                    className="card-img-top"
                                    width={20}
                                    height={20}
                                  />
                                </button>
                              </div>
                            </div>
                            <div>
                              <p>{subTitle ? subTitle : ""}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="d-flex flex-row w-50 justify-content-between cart-count-container">
                                <div>
                                  <button
                                    onClick={() =>
                                      handleUpdateProduct(
                                        line.node.id,
                                        line.node.quantity - 1
                                      )
                                    }
                                    style={{
                                      display: "block",
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <Image
                                      src="/assets/images/new-image/cart/cart-minus.png"
                                      alt="Minus"
                                      className="card-img-top"
                                      width={20}
                                      height={20}
                                    />
                                  </button>
                                </div>
                                <p className="count mb-0">
                                  {line.node.quantity}
                                </p>
                                <div>
                                  <button
                                    onClick={() =>
                                      handleUpdateProduct(
                                        line.node.id,
                                        line.node.quantity + 1
                                      )
                                    }
                                    style={{
                                      display: "block",
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <Image
                                      src="/assets/images/new-image/cart/cart-plus.png"
                                      alt="Plus"
                                      className="card-img-top"
                                      width={20}
                                      height={20}
                                    />
                                  </button>
                                </div>
                              </div>
                              <p className="mb-0 product-price">
                                $
                                {parseFloat(
                                  line.node.merchandise?.priceV2?.amount
                                ).toFixed(2)}
                              </p>
                            </div>
                            {/* ) : ( */}
                            {/* <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    <button type='button' className='btn text-white w-100 add-to-cart-btn'>
                                                        Add to cart
                                                    </button>
                                                    <p className='mb-0 product-price'>${product.price}</p>
                                                </div>
                                            )} */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>Your Cart is Empty</p>
                )}
              </div>
            </div>

            {showRecommended && (
              <div className="product-type-holder pt-0">
                <div className="pb-3" style={{ backgroundColor: "#E1F5DA" }}>
                  <div className="mx-4 mx-sm-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="m-3">Recommended</h5>
                      <div>
                        <button
                          onClick={handleCloseRecommended}
                          style={{
                            display: "block",
                            border: "none",
                            background: "none",
                          }}
                        >
                          <Image
                            src="/assets/images/new-image/Close.png"
                            alt="Remove"
                            className="card-img-top"
                            width={20}
                            height={20}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Image
                          src="/assets/images/v2/menu/blackSetTrans.png"
                          alt="Recommended Product"
                          className="card-img-top w-100 h-100"
                          width={180}
                          height={180}
                          style={{
                            border: "1px solid #848484",
                            borderRadius: "14px",
                          }}
                        />
                      </div>

                      <div className="col-8">
                        <div className="d-flex flex-row justify-content-between">
                          <p
                            className="product-name text-dark"
                            style={{ fontSize: "20px" }}
                          >
                            Upgrade to the Black Set
                          </p>
                        </div>
                        <div>
                          <p style={{ color: "red", fontSize: "16px" }}>
                            Black Friday Limited Edition
                          </p>
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <button
                            onClick={() =>
                              handleAddToCartRecommended(
                                recommendedSetProductId,
                                recommendedSetVariantId[0]
                              )
                            }
                            className="btn btn-primary text-white continue-btn"
                          >
                            Add to cart
                          </button>
                          {/* </div> */}
                          <p className="mb-0 product-price">{shopifyPrice}</p>
                          <p
                            className="mb-0 product-price"
                            style={{
                              color: "#848484",
                              textDecoration: "line-through",
                            }}
                          >
                            {compareAtPrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="px-4 py-5" style={{ marginBottom: "20px" }}>
              <h4 style={{ paddingTop: "24px", paddingBottom: "24px" }}>
                Special Payment options:
              </h4>
              <button
                type="button"
                className="btn w-100 "
                style={{
                  backgroundColor: "#DCDFE2",
                  padding: "8px 24px",
                  borderRadius: "30px",
                  height: "65px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  handleCheckout("hsa");
                }}
              >
                <Image
                  src="/assets/images/g14.png"
                  alt="img"
                  width={150}
                  height={20}
                />
                <span
                  className="text-black hsaContent"
                  style={{ fontWeight: "bold" }}
                >
                  - Pay with HSA/FSA
                </span>
              </button>
              <button
                className="govx-id black w-100 btn"
                style={{
                  backgroundColor: "#DCDFE2",
                  padding: "8px 24px",
                  borderRadius: "30px",
                  height: "65px",
                  gap: "10px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  handleGovx();
                }}
              >
                <img src="/assets/images/govXID.png" />
              </button>
            </div>

            <div className="px-4">
              <p style={{ fontSize: "24px", color: "#0C1218" }}>FAQs</p>
            </div>
            <div className="faq-section d-flex">
              <div className="w-100">
                <div className="accordion bg-transparent" id="accordionExample">
                  {hsaFsaFaq.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="accordion-item bg-transparent px-3 border-0"
                    >
                      <h3 className="accordion-header border-top">
                        <button
                          className={`accordion-button  ${
                            activeIndex === index ? "" : "collapsed"
                          } bg-transparent `}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                        >
                          {item.title}
                        </button>
                      </h3>
                      <div
                        className={`accordion-collapse collapse ${
                          activeIndex === index ? "show" : ""
                        }`}
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          style={{ color: "black" }}
                          className="accordion-body bg-transparent  px-4"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description ?? "",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="px-4 py-5 detail-cart">
              <div className="d-flex flex-row justify-content-between">
                <p style={{ fontSize: "16px" }}>Shipping</p>
                <p style={{ fontSize: "16px" }}>calculated at checkout</p>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <p className="sub-total">Subtotal</p>
                <p className="total-price">${subtotal}</p>
              </div>
              <hr />

              <button
                type="button"
                className="btn text-white w-100 checkout-btn"
                onClick={() => {
                  handleCheckout("normal");
                }}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 992px) {
          .hsaContent {
            width: 50%;
            font-size: 20px;
          }
        }
        @media (max-width: 768px) {
          .hsaContent {
            width: 60%;
            font-size: 20px;
          }
        }
        @media (max-width: 480px) {
          .hsaContent {
            width: 60%;
            font-size: 16px;
          }
        }
      `}</style>
    </>
  );
}
// import React, { useState } from 'react';
// import Image from 'next/image';

// const products = [
//     {
//         id: 1,
//         name: 'Orbit',
//         image: '/assets/images/new-image/cart/orbit-cart.png',
//         price: 24,
//         quantity: 0,
//     },
//     {
//         id: 2,
//         name: 'Range',
//         image: '/assets/images/new-image/cart/range-cart.png',
//         price: 24,
//         quantity: 0,
//     },
//     {
//         id: 3,
//         name: 'Upgrade to the Set',
//         image: '/assets/images/new-image/cart/set-cart.png',
//         price: 299,
//         quantity: 1,
//     },
// ];

// export default function ProductCartSection() {
//     return (
//         <div className='container'>
//             <div className='cart-section d-flex flex-column mx-auto'>
//                 <div className='w-100 bg-white'>
//                     <div className="mx-4 mx-sm-5">
//                         {products.map((product) => (
//                             <div key={product.id} className='product-type-holder mb-4'>
//                                 <div className='row align-items-center'>
//                                     <div className='col-12 col-sm-4 mb-3 mb-sm-0'>
//                                         <Image
//                                             src={product.image}
//                                             alt={product.name}
//                                             className='card-img-top w-100 h-auto'
//                                             width={180}
//                                             height={180}
//                                         />
//                                     </div>
//                                     <div className='col-12 col-sm-8'>
//                                         <div className='d-flex flex-row justify-content-between align-items-start'>
//                                             <p className='product-name text-dark'>{product.name}</p>
//                                             <div>
//                                                 <Image
//                                                     src='/assets/images/new-image/Close.png'
//                                                     alt='Remove'
//                                                     className='card-img-top'
//                                                     width={20}
//                                                     height={20}
//                                                 />
//                                             </div>
//                                         </div>
//                                         {product.id !== 3 ? (
//                                             <div className='d-flex flex-row justify-content-between align-items-center'>
//                                                 <div className='d-flex flex-row justify-content-between cart-count-container'>
//                                                     <div>
//                                                         <Image
//                                                             src='/assets/images/new-image/cart/cart-minus.png'
//                                                             alt='Minus'
//                                                             className='card-img-top'
//                                                             width={20}
//                                                             height={20}
//                                                         />
//                                                     </div>
//                                                     <p className='count mb-0 mx-3'>{product.quantity}</p>
//                                                     <div>
//                                                         <Image
//                                                             src='/assets/images/new-image/cart/cart-plus.png'
//                                                             alt='Plus'
//                                                             className='card-img-top'
//                                                             width={20}
//                                                             height={20}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <p className='mb-0 product-price'>${product.price}</p>
//                                             </div>
//                                         ) : (
//                                             <div className='d-flex flex-row justify-content-between align-items-center'>
//                                                 <button type='button' className='btn text-white w-100 add-to-cart-btn'>
//                                                     Add to cart
//                                                 </button>
//                                                 <p className='mb-0 product-price'>${product.price}</p>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>

//                     <div className='px-4 py-5 detail-cart'>
//                         <div className='d-flex flex-row justify-content-between'>
//                             <p className='sub-total'>Subtotal</p>
//                             <p className='total-price'>$223.00</p>
//                         </div>
//                         <hr />
//                         <p className='text-center desc-message'>You've earned <strong>FREE US SHIPPING!</strong></p>
//                         <div className='blue-bar w-75 mx-auto mt-4 mb-4'></div>
//                         <button type='button' className='btn text-white w-100 checkout-btn'>
//                             Checkout
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }
