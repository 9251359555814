// utils/axios.ts
import axios from 'axios';
import { NEXT_PUBLIC_API_BASE_URL } from './constants';

const instance = axios.create({
  baseURL: NEXT_PUBLIC_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
