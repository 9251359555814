"use client";

import React from "react";
import { Modal } from "react-bootstrap";
import ProductCartSection from "../cart-1/cartSection";

interface CartModalProps {
  cartShow: boolean;
  handleClose: () => void;
}

const CartModal: React.FC<CartModalProps> = ({ cartShow, handleClose }) => {
  return (
    <Modal style={{zIndex: 100000}} show={cartShow} onHide={handleClose} centered dialogClassName="custom-modal">
      {" "}

      <Modal.Header closeButton className="custom-model-head">
        <h1 className="fw-bold">Your Cart</h1>
      </Modal.Header>
      <Modal.Body  className="p-0">
        <ProductCartSection />
      </Modal.Body>
    </Modal>
  );
};

export default CartModal;
