import React from 'react';

interface ShippingProgressBarProps {
  subtotal: number;
  freeShippingThreshold: number;
}

const ShippingProgressBar: React.FC<ShippingProgressBarProps> = ({ subtotal, freeShippingThreshold = 75 }) => {

  const subtotalValue = typeof subtotal === 'string' ? parseFloat(subtotal) : subtotal;
  const progressPercentage = Math.min((subtotal / freeShippingThreshold) * 100, 100);
  const remainingToFreeShipping = Math.max(freeShippingThreshold - subtotalValue, 0);

  return (
    <div className="shipping-progress-bar-container">

      <p className="shipping-progress-message">
        {subtotalValue >= freeShippingThreshold
          ? "You've earned"
          : `You're $${remainingToFreeShipping} from `}
           <span className="bold-text"> FREE US SHIPPING.</span>
      </p>

      <div className="shipping-progress-bar">

        <div className="progress-bar-bg"></div>
        
        <div className="progress-bar-fill" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      
  
      <div className="progress-bar-amounts">
      {subtotalValue < 75 ? (
        <>
        <span className="subtotal">${subtotalValue.toFixed(0)}</span>
        <span className="free-shipping">${freeShippingThreshold}</span>
        </>
         ) : null}
      </div>
    </div>
  );
};

export default ShippingProgressBar;
